<template>
  <div class="write-page-heng-container">
    <WritePinyinPage
      :pinyinImgList="pinyinImgList"
      :strokeTimesList="strokeTimesList"
      :buttonList="buttonList"
    />
  </div>
</template>
<script>
import WritePinyinPage from "@/components/Course/CoursePage/WritePinyinPage";

export default {
  data() {
    return {
      pinyinList: ["a", "o", "e"], //ü
      strokeTimesList: [2, 1, 1],
    };
  },
  computed: {
    pinyinImgList() {
      let imgArr = [];
      for (let i = 0; i < 3; i++) {
        const ele = {
          pinyinImg: require(`@/assets/img/14-Pinyin/chapter-01/pinyin-${this.pinyinList[i]}-gif.svg`), //strokeImg
          tipsImg: require(`@/assets/img/14-Pinyin/chapter-01/chapter-01-${this.pinyinList[i]}.gif`),
          redBgImg: "", //strokeImgFill
        };
        imgArr.push(ele);
      }
      return imgArr;
    },
    buttonList() {
      let menuArr = [];
      for (let j = 0; j < 3; j++) {
        const item = {
          image: require(`@/assets/img/14-Pinyin/menu/pinyin-${this.pinyinList[j]}.svg`),
          startNumberRange: j + 1,
          endNumberRange: j + 1,
        };
        menuArr.push(item);
      }
      return menuArr;
    },
  },
  components: {
    WritePinyinPage,
  },
};
</script>
<style lang="scss" scoped>
.write-page-heng-container {
  width: 100%;
  height: 100%;
}
</style>
