var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "story-page-one-container" },
    [_c("StoryPage", { attrs: { bgImg: _vm.bgImg } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }