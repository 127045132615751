<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-7.mp3"),
      },
      timeStampList: [10.4, 15.8,20.8,26.1, 41.7, 44.2,46.9,49.7, 52],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "a  o  e  i  u  ü",
          },
          {
            id: 2,
            chineseWords: "ā á ǎ à, "
          },
          {
            id: 3,
            chineseWords :"ō ó ǒ ò,"
          },
          {
            id: 4,
            chineseWords :"ē é ě è,",
          },
          {
            id: 5,
            chineseWords: "ī í ǐ ì,",
          },
          {
            id: 6,
            chineseWords :"ū ú ǔ ù,",
          },
          {
            id: 7,
            chineseWords :" ǖ ǘ ǚ ǜ,",
          },
          {
            id: 8,
            chineseWords: "a  o  e  i  u  ü",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
