<template>
  <!-- layout-container lesson 1 -->
  <div class="lesson-one-container">
    <div class="display-page" v-if="pageType === 0">
      <template
        v-for="(item, key, index) in componentsList"
        class="display-page"
      >
        <keep-alive :key="index + 'keep'">
          <transition name="slide-fade" :key="index">
            <component :key="index" :is="item" v-if="index + 1 === pageNum" :lesson="7">
            </component>
          </transition>
        </keep-alive>
      </template>
    </div>
    <div class="display-page" v-if="pageType === 1">
      <SyllabusPageA />
    </div>
    <div class="display-page test" v-if="pageType === 2">
      <WhiteBoard />
    </div>
    <div class="whiteBoard-box" v-if="globalWhiteB">
      <!-- 白板部分 -->
      <WhiteBoard :canvasHeight="cvHeight" :canvasWidth="cvWidth" />
    </div>
  </div>
</template>
<script>
import { lessonMixin } from "@/mixins/lessonDefault.js";
import SyllabusPageA from "./3_SyllabusPage_A.vue";
import WhiteBoard from "@/components/Course/WhiteBoard/WhiteBoard.vue";

import LessonPage from "./1_Lesson.vue";
import RankMapPageOne from "./2_RankMapPageOne.vue";
import TargetPage from "./3_Target.vue";
import OpeningSongPage from "./4_OpeningSongPage.vue";
import IntroductionPage from "./5_Introduction.vue";
import StoryPageOne from "./6_StoryPageOne.vue";
import RecognitionPage_a from "./7_RecognitionPage_a.vue";
import RecognitionPage_o from "./8_RecognitionPage_o.vue";
import RecognitionPage_e from "./9_RecognitionPage_e.vue";
import PracticePageOne from "./10_PracticePageOne.vue";
import StoryPageTwo from "./11_StoryPageTwo.vue";
import RecognitionPage_i from "./12_RecognitionPage_i.vue";
import RecognitionPage_u from "./13_RecognitionPage_u.vue";
import RecognitionPage_yu from "./14_RecognitionPage_yu.vue";
import PracticePageTwo from "./15_PracticePageTwo.vue";
import WordsCardPage from "./16_WordsCardPage.vue";
import StoryPageThree from "./17_StoryPageThree.vue";
import RecognitionPageTone_a from "./18_RecognitionPage_tone_a.vue";
import RecognitionPageTone_o from "./19_RecognitionPage_tone_o.vue";
import RecognitionPageTone_e from "./20_RecognitionPage_tone_e.vue";
import PracticePageThree from "./21_PracticePageThree.vue";
import RecognitionPageTone_i from "./22_RecognitionPage_tone_i.vue";
import RecognitionPageTone_u from "./23_RecognitionPage_tone_u.vue";
import RecognitionPageTone_yu from "./24_RecognitionPage_tone_yu.vue";
import PracticePageFour from "./25_PracticePageFour.vue";
import PracticePageFive from "./26_PracticePageFive.vue";
import WritingPageA from "./27_WritingPageA.vue";
import WritingPageB from "./28_WritingPageB.vue";
import PracticePageSix from "./29_PracticePageSix.vue";
import PracticePageSeven from "./30_PinyinSummary.vue";
import RankMapPageTwo from "./31_RankMapPageTwo.vue";
import ByePage from "./32_ByePage.vue";
export default {
  name: "Lesson7",
  mixins: [lessonMixin],
  components: {
    SyllabusPageA,
    WhiteBoard,
    LessonPage,
    RankMapPageOne,
    TargetPage,
    OpeningSongPage,
    IntroductionPage,
    StoryPageOne,
    RecognitionPage_a,
    RecognitionPage_o,
    RecognitionPage_e,
    PracticePageOne,
    StoryPageTwo,
    RecognitionPage_i,
    RecognitionPage_u,
    RecognitionPage_yu,
    PracticePageTwo,
    WordsCardPage,
    StoryPageThree,
    RecognitionPageTone_a,
    RecognitionPageTone_o,
    RecognitionPageTone_e,
    PracticePageThree,
    RecognitionPageTone_i,
    RecognitionPageTone_u,
    RecognitionPageTone_yu,
    PracticePageFour,
    PracticePageFive,
    WritingPageA,
    WritingPageB,
    PracticePageSix,
    PracticePageSeven,
    RankMapPageTwo,
    ByePage,
  },
  data() {
    return {
      componentsList: {
        LessonPage,
        RankMapPageOne,
        TargetPage,
        OpeningSongPage,
        IntroductionPage,
        StoryPageOne,
        RecognitionPage_a,
        RecognitionPage_o,
        RecognitionPage_e,
        PracticePageOne,
        StoryPageTwo,
        RecognitionPage_i,
        RecognitionPage_u,
        RecognitionPage_yu,
        PracticePageTwo,
        WordsCardPage,
        StoryPageThree,
        RecognitionPageTone_a,
        RecognitionPageTone_o,
        RecognitionPageTone_e,
        PracticePageThree,
        RecognitionPageTone_i,
        RecognitionPageTone_u,
        RecognitionPageTone_yu,
        PracticePageFour,
        PracticePageFive,
        WritingPageA,
        WritingPageB,
        PracticePageSix,
        PracticePageSeven,
        RankMapPageTwo,
        ByePage,
      },
    };
  },
};
</script>
>

<style lang="scss" scoped>
.lesson-one-container {
  width: 100%;
  height: 100%;
  .whiteBoard-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1;
  }

  // .page-item {
  //   width: 100%;
  //   height: 100%;

  .display-page {
    width: 100%;
    height: 100%;
    // background-color: #fff;
    border-radius: 56px;
  }
  .test {
    background: #fff;
  }

  // }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}
</style>
