<template>
  <div class="game-container">
    <PinyinPuzzleGame :optionList="optionList" :answerList="answerList"/>
  </div>
</template>
<script>
import PinyinPuzzleGame from "@/components/Course/GamePage/PinyinPuzzleGame";

export default {
  components: {
    PinyinPuzzleGame,
  },
  data() {
    return {
      optionList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-a1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-i2.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-u4.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-a4.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-o3.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-e2.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-u1.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-o4.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-i3.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-ü.svg"),
            showObj: true,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-ü4.svg"),
            showObj: true,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-ü3.svg"),
            showObj: true,
          },
        ],
      ],

      answerList: [
        {
          id: 1,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ba1.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-b.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-a1-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-character-ba.svg"),
        },
        {
          id: 2,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/wo3.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-w.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-o3-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-character-wo.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/ni3.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-n.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-i3-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-character-ni.svg"),
        },
        {
          id: 3,
          audioUrl: require("@/assets/audio/L1/3-Pinyin-syllables/wu3.mp3"),
          isCorrect: false,
          firstImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-w.svg"),
          secondImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-u3-correct.svg"),
          fullImg: require("@/assets/img/16-GAMES/G46-puzzle/puzzle-character-wu.svg"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.game-container {
  width: 100%;
  height: 100%;
}
</style>
