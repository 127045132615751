<template>
  <div class="game-container">
    <RecognitionPinyinBasketBallGame
      :answerList="answerList"
      :optionList="optionList"
      :audioList="audioList"
    />
  </div>
</template>

<script>
import RecognitionPinyinBasketBallGame from "@/components/Course/GamePage/RecognitionPinyinBasketBallGame";
export default {
  data() {
    return {
      lessonId: 7,
      answerList: [
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 2,
          sound: "",
          showObj: true,
        },
        {
          id: 4,
          sound: "",
          showObj: true,
        },
        {
          id: 1,
          sound: "",
          showObj: true,
        },
        {
          id: 3,
          sound: "",
          showObj: true,
        },
      ],

      optionList: [
        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a4.svg"),
            showObj: true,
          },
        ],

        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o4.svg"),
            showObj: true,
          },
        ],

        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e4.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a3.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o2.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o4.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e4.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a4.svg"),
            showObj: true,
          },
        ],
        [
          {
            id: 1,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e1.svg"),
            showObj: true,
          },
          {
            id: 2,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-a2.svg"),
            showObj: true,
          },
          {
            id: 3,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-e3.svg"),
            showObj: true,
          },
          {
            id: 4,
            bottomImg: require("@/assets/img/16-GAMES/G45-basketball/basketball-o4.svg"),
            showObj: true,
          },
        ],
      ],
      audioList:[
        require("@/assets/audio/L1/2-Pinyin-tones/a1.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/o4.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/e2.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/a3.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/e4.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/o1.mp3"),
        require("@/assets/audio/L1/2-Pinyin-tones/e3.mp3"),
      ]
    };
  },
  components: {
    RecognitionPinyinBasketBallGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











